.box-container {
  width: 90%;
}

.box-tag-1 {
  background-color: #c4c2ff;
  max-height: 216.8px;
  height: 216.8px;
}

.box-data-1 {
  background-color: #ffe76e;
}

.box-data-2 {
  background-color: #6e95ff;
}
.home-title {
  font-size: 2.4em;
  letter-spacing: 2px;
}
.home-list {
  font-size: 1.1em;
}
@font-face {
  font-family: Great-Vibes;
  src: url("../assets/fonts/great-vibes.ttf") format("truetype");
}
@font-face {
  font-family: Barlow-ExtraLight;
  src: url("../assets/fonts/barlow-extra-light.ttf") format("truetype");
}
@font-face {
  font-family: Barlow-Medium;
  src: url("../assets/fonts/barlow-medium.ttf") format("truetype");
}
@font-face {
  font-family: Montserrat-Light;
  src: url("../assets/fonts/montserrat-light.ttf");
}
@font-face {
  font-family: Barlow-Condensed;
  src: url("../assets/fonts/barlow-condensed-regular.ttf") format("truetype");
}
.font-greatvibes {
  font-family: Great-Vibes;
}
.font-barlowextralight {
  font-family: Barlow-ExtraLight;
}
.font-barlowmedium {
  font-family: Barlow-Medium;
}
.font-montserratlight {
  font-family: Montserrat-Light;
}
.font-barlowcondensed {
  font-family: Barlow-Condensed;
}
