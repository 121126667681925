.img-resize {
  width: 22em; /* You can set the dimensions to whatever you want */
  height: 22em;
  object-fit: cover;
}
/* css */
html {
  scroll-behavior: smooth;
}
.overtext {
  color: white;
}
.slides {
  height: 589.5px;
  max-height: 589.5px;
  background-size: cover;
  background-position: center;
}

.Squish0 {
  background-image: url("../assets/games/Squish/SquishPoster.png");
}
.Squish1 {
  background-image: url("../assets/games/Squish/TitleScreen_4K.jpg");
}
.Squish2 {
  background-image: url("../assets/games/Squish/Squish Lobby.jpg");
}
.Squish3 {
  background-image: url("../assets/games/Squish/Squish1.jpg");
}
.Squish4 {
  background-image: url("../assets/games/Squish/Squish2.jpg");
}
.Squish5 {
  background-image: url("../assets/games/Squish/Squish3.jpg");
}
.Squish6 {
  background-image: url("../assets/games/Squish/Squish4.jpg");
}
.Squish7 {
  background-image: url("../assets/games/Squish/Squish5.jpg");
}

.KO0 {
  background-image: url("../assets/games/KillerOutfit/portfolio6.png");
}
.KO1 {
  background-color: gray;
  background-image: url("../assets/games/KillerOutfit/bannerr.jpg");
  background-size: initial;
  background-repeat: no-repeat;
}
.KO2 {
  background-image: url("../assets/games/KillerOutfit/portfolio1.png");
}
.KO3 {
  background-image: url("../assets/games/KillerOutfit/portfolio2.png");
}
.KO4 {
  background-image: url("../assets/games/KillerOutfit/portfolio3.png");
}
.KO5 {
  background-image: url("../assets/games/KillerOutfit/portfolio4.png");
}
.KO6 {
  background-image: url("../assets/games/KillerOutfit/portfolio5.png");
}
.KO7 {
  background-image: url("../assets/games/KillerOutfit/KillerOutfitEnvironment.png");
}

.Hemo0 {
  background-color: gray;
  background-image: url("../assets/games/Hemogoblin//Title Screen.png");
  background-size: initial;
  background-repeat: no-repeat;
}
.Hemo1 {
  background-image: url("../assets/games/Hemogoblin//Hemogoblin_Capture.PNG.png");
}
.Hemo2 {
  background-image: url("../assets/games/Hemogoblin//Lvl1.png");
}
.Hemo3 {
  background-image: url("../assets/games/Hemogoblin//Levl2.png");
}
.Hemo4 {
  background-color: gray;
  background-image: url("../assets/games/Hemogoblin//Hemo_Capture.PNG.png");
  background-size: initial;
  background-repeat: no-repeat;
}
.Hemo5 {
  background-color: gray;
  background-image: url("../assets/games/Hemogoblin//Hemo1_Capture.PNG.png");
  background-size: initial;
  background-repeat: no-repeat;
}
.Hemo6 {
  background-color: gray;
  background-image: url("../assets/games/Hemogoblin//Hemo2_Capture.PNG.png");
  background-size: initial;
  background-repeat: no-repeat;
}

.Df20 {
  background-image: url("../assets/games/DinnerForTwo/20180321_130325.jpg");
}
.Df21 {
  background-color: gray;
  background-image: url("../assets/games/DinnerForTwo/20180321_130340_resize.png");
  background-size: initial;
  background-repeat: no-repeat;
}
.Df22 {
  background-image: url("../assets/games/DinnerForTwo/20180321_130422.jpg");
}
.Df23 {
  background-image: url("../assets/games/DinnerForTwo/20180321_130408.jpg");
}
.Df24 {
  background-color: gray;
  background-image: url("../assets/games/DinnerForTwo/20180407_234716_2.jpg");
}
.Df25 {
  background-image: url("../assets/games/DinnerForTwo/20180311_163435.jpg");
  transform: rotate(270deg) scale(1.55, 1.55);
  background-color: gray;
}

.NG0 {
  background-color: gray;
  background-image: url("../assets/games/NormalGirl/Front_Only.png");
  background-size: initial;
  background-repeat: no-repeat;
}
.NG1 {
  background-color: gray;
  background-image: url("../assets/games/NormalGirl/Normal Girl_Capture.PNG.png");
  background-size: initial;
  background-repeat: no-repeat;
}
.NG2 {
  background-color: gray;
  background-image: url("../assets/games/NormalGirl/NG_3Capture.PNG.png");
  background-size: initial;
  background-repeat: no-repeat;
}
.NG3 {
  background-color: gray;
  background-image: url("../assets/games/NormalGirl/NG_2Capture.PNG.png");
  background-size: initial;
  background-repeat: no-repeat;
}
.NG4 {
  background-image: url("../assets/games/NormalGirl/NGD_1Capture.PNG.png");
}
.NG5 {
  background-image: url("../assets/games/NormalGirl/NGD_Capture.PNG.png");
}
.NG6 {
  background-color: gray;
  background-image: url("../assets/games/NormalGirl/NG_1Capture.PNG.png");
  background-size: initial;
  background-repeat: no-repeat;
}

.UCSC0 {
  background-color: gray;
  background-image: url("../assets/games/UCSCGuardians/redmidas_resize.png");
  background-size: initial;
  background-repeat: no-repeat;
}
.UCSC1 {
  background-color: gray;
  background-image: url("../assets/games/UCSCGuardians/whitemidas_resize1.png");
  background-size: initial;
  background-repeat: no-repeat;
}
.UCSC2 {
  background-color: gray;
  background-image: url("../assets/games/UCSCGuardians/slug_rosetta_stone.png");
  background-size: initial;
  background-repeat: no-repeat;
}
.UCSC3 {
  background-image: url("../assets/games/UCSCGuardians/Deer_resize.png");
}
.UCSC4 {
  background-image: url("../assets/games/UCSCGuardians/image0\ \(3\).jpg");
}
.UCSC5 {
  background-image: url("../assets/games/UCSCGuardians/image0\ \(1\).jpg");
}
.UCSC6 {
  background-image: url("../assets/games/UCSCGuardians/image0\ \(2\).jpg");
}

.hidden {
  display: none;
}
.hovereffect {
  width: 100%;
  height: 100%;
  float: left;
  overflow: hidden;
  position: relative;
  text-align: center;
  cursor: default;
}

.hovereffect .overlay {
  width: 100%;
  height: 100%;
  position: absolute;
  overflow: hidden;
  top: 0;
  left: 0;
}

.hovereffect img {
  display: block;
  position: relative;
  -webkit-transition: all 0.4s ease-in;
  transition: all 0.4s ease-in;
}

.hovereffect:hover img {
  filter: url('data:image/svg+xml;charset=utf-8,<svg xmlns="http://www.w3.org/2000/svg"><filter id="filter"><feColorMatrix type="matrix" color-interpolation-filters="sRGB" values="0.2126 0.7152 0.0722 0 0 0.2126 0.7152 0.0722 0 0 0.2126 0.7152 0.0722 0 0 0 0 0 1 0" /><feGaussianBlur stdDeviation="3" /></filter></svg>#filter');
  filter: grayscale(1) blur(3px);
  -webkit-filter: grayscale(1) blur(3px);
  -webkit-transform: scale(1.05);
  -ms-transform: scale(1.05);
  transform: scale(1.05);
}

.hovereffect h2 {
  text-transform: uppercase;
  text-align: center;
  position: relative;
  font-size: 17px;
  padding: 10px;
  background: rgba(0, 0, 0, 0.6);
}

.hovereffect a.info {
  display: inline-block;
  text-decoration: none;
  padding: 7px 14px;
  border: 1px solid #fff;
  margin: 50px 0 0 0;
  background-color: transparent;
}

.hovereffect a.info:hover {
  box-shadow: 0 0 5px #fff;
}

.hovereffect a.info,
.hovereffect h2 {
  -webkit-transform: scale(0.7);
  -ms-transform: scale(0.7);
  transform: scale(0.7);
  -webkit-transition: all 0.4s ease-in;
  transition: all 0.4s ease-in;
  opacity: 0;
  filter: alpha(opacity=0);
  color: #fff;
  text-transform: uppercase;
}

.hovereffect:hover a.info,
.hovereffect:hover h2 {
  opacity: 1;
  filter: alpha(opacity=100);
  -webkit-transform: scale(1);
  -ms-transform: scale(1);
  transform: scale(1);
}

.hr-divider {
  height: 30px;
  border-style: solid;
  border-color: #8c8b8b;
  border-width: 1px 0 0 0;
  border-radius: 20px;
}
.hr-divider:before {
  display: block;
  content: "";
  height: 30px;
  margin-top: -31px;
  border-style: solid;
  border-color: #8c8b8b;
  border-width: 0 0 1px 0;
  border-radius: 20px;
}
@media only screen and (max-width: 959px) {
  .item {
    max-height: 159.183px;
  }
}

.btn-caro:link,
.btn-caro:visited {
  text-transform: uppercase;
  text-decoration: none;
  padding: 15px 40px;
  display: inline-block;
  border-radius: 100px;
  transition: all 0.2s;
  position: absolute;
}

.btn-caro:hover {
  transform: translateY(-3px);
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
  color: white;
}

.btn-caro:active {
  transform: translateY(-1px);
  box-shadow: 0 5px 10px rgb(0, 0, 0);
}

.btn-caro-white {
  background: #8884ff;
  color: white;
  border-radius: 25px;
}

.btn-caro::after {
  border-radius: 200px;
  z-index: -1;
  transition: all 0.4s;
}

.btn-caro-white::after {
  background: #8884ff;
}

.btn-caro:hover::after {
  transform: scaleX(1.4) scaleY(1.6);
  opacity: 0;
}

.btn-caro-animated {
  animation: moveInBottom 5s ease-out;
  animation-fill-mode: backwards;
}

@keyframes moveInBottom {
  0% {
    opacity: 0;
    transform: translateY(30px);
  }

  100% {
    opacity: 1;
    transform: translateY(0px);
  }
}
