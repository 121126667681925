.fill {
  min-height: 100vh;
  height: 100%;
}
.letter-image {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 200px;
  height: 200px;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  cursor: pointer;
}

.animated-mail {
  position: absolute;
  height: 150px;
  width: 200px;
  -webkit-transition: 0.4s;
  -moz-transition: 0.4s;
  transition: 0.4s;

  .body {
    position: absolute;
    bottom: 0;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 0 100px 200px;
    border-color: transparent transparent #e95f55 transparent;
    z-index: 2;
  }

  .top-fold {
    position: absolute;
    top: 50px;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 50px 100px 0 100px;
    -webkit-transform-origin: 50% 0%;
    -webkit-transition: transform 0.4s 0.4s, z-index 0.2s 0.4s;
    -moz-transform-origin: 50% 0%;
    -moz-transition: transform 0.4s 0.4s, z-index 0.2s 0.4s;
    transform-origin: 50% 0%;
    transition: transform 0.4s 0.4s, z-index 0.2s 0.4s;
    border-color: #cf4a43 transparent transparent transparent;
    z-index: 2;
  }

  .back-fold {
    position: absolute;
    bottom: 0;
    width: 200px;
    height: 100px;
    background: #cf4a43;
    z-index: 0;
  }

  .left-fold {
    position: absolute;
    bottom: 0;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 50px 0 50px 100px;
    border-color: transparent transparent transparent #e15349;
    z-index: 2;
  }

  .letter {
    left: 20px;
    bottom: 0px;
    position: absolute;
    width: 160px;
    height: 60px;
    background: white;
    z-index: 1;
    overflow: hidden;
    -webkit-transition: 0.4s 0.2s;
    -moz-transition: 0.4s 0.2s;
    transition: 0.4s 0.2s;

    .letter-border {
      height: 10px;
      width: 100%;
      background: repeating-linear-gradient(
        -45deg,
        #cb5a5e,
        #cb5a5e 8px,
        transparent 8px,
        transparent 18px
      );
    }

    .letter-title {
      margin-top: 10px;
      margin-left: 5px;
      height: 10px;
      width: 40%;
      background: #cb5a5e;
    }
    .letter-context {
      margin-top: 10px;
      margin-left: 5px;
      height: 10px;
      width: 20%;
      background: #cb5a5e;
    }

    .letter-stamp {
      margin-top: 30px;
      margin-left: 120px;
      border-radius: 100%;
      height: 30px;
      width: 30px;
      background: #cb5a5e;
      opacity: 0.3;
    }
  }
}

.letter-shadow {
  position: absolute;
  top: 200px;
  left: 50%;
  width: 400px;
  height: 30px;
  transition: 0.4s;
  transform: translateX(-50%);
  -webkit-transition: 0.4s;
  -webkit-transform: translateX(-50%);
  -moz-transition: 0.4s;
  -moz-transform: translateX(-50%);

  border-radius: 100%;
  background: radial-gradient(
    rgba(0, 0, 0, 0.5),
    rgba(0, 0, 0, 0),
    rgba(0, 0, 0, 0)
  );
}

.letter-image:hover {
  .animated-mail {
    transform: translateY(50px);
    -webkit-transform: translateY(50px);
    -moz-transform: translateY(50px);
  }

  .animated-mail .top-fold {
    transition: transform 0.4s, z-index 0.2s;
    transform: rotateX(180deg);
    -webkit-transition: transform 0.4s, z-index 0.2s;
    -webkit-transform: rotateX(180deg);
    -moz-transition: transform 0.4s, z-index 0.2s;
    -moz-transform: rotateX(180deg);
    z-index: 0;
  }

  .animated-mail .letter {
    height: 180px;
  }

  .letter-shadow {
    width: 250px;
  }
}

$separator-size: 2px;
$separator-border-style: solid;
$separator-border-color: #696969;
.separator {
  border: 0;
}
.separator--line {
  border: 0;
  border-bottom: $separator-size $separator-border-style $separator-border-color;

  width: 0;
  animation: separator-width 1s ease-out forwards;
}
@keyframes separator-width {
  0% {
    width: 0;
  }
  100% {
    width: 100%;
  }
}
// @mixin dot {
//   $dot-size: 7px;
//   content: "";
//   background-color: $separator-border-color;
//   display: block;
//   height: $dot-size;
//   width: $dot-size;
//   border-radius: 50%;
// }
// .separator--dots {
//   overflow: visible;
//   border: 0;
//   @include dot();
//   position: relative;
//   &:before {
//     @include dot();
//     position: absolute;
//     left: -32px;
//     animation: dot-move-left 1s ease-out forwards;
//   }
//   &:after {
//     @include dot();
//     position: absolute;
//     left: 32px;
//     animation: dot-move-right 1s ease-out forwards;
//   }
// }

// @keyframes dot-move-right {
//   0% {
//     left: 0;
//   }
//   100% {
//     left: 32px;
//   }
// }
// @keyframes dot-move-left {
//   0% {
//     left: 0;
//   }
//   100% {
//     left: -32px;
//   }
// }
// @keyframes dot-move-right2 {
//   0% {
//     left: 0;
//   }
//   100% {
//     left: 64px;
//   }
// }
// @keyframes dot-move-left2 {
//   0% {
//     left: 0;
//   }
//   100% {
//     left: -64px;
//   }
// }
.separator-side {
  margin-top: 20px;
  margin-bottom: 20px;
  border: 0;
}
.separator--dotter {
  border: 0;
  border-bottom: 5px dotted black;

  width: 0;
  animation: separator-width 1.5s ease-out forwards;
}
.btn-send {
  background-color: #c4c2ff;
}
@mixin btn-border-drawing(
  $color: #8884ff,
  $hover: black,
  $width: 2px,
  $vertical: top,
  $horizontal: left,
  $duration: 0.25s
) {
  box-shadow: inset 0 0 0 $width $color;
  color: $color;
  transition: color $duration $duration/3;
  position: relative;

  &::before,
  &::after {
    border: 0 solid transparent;
    box-sizing: border-box;
    content: "";
    pointer-events: none;
    position: absolute;
    width: 0;
    height: 0;

    #{$vertical}: 0;
    #{$horizontal}: 0;
  }

  &::before {
    $h-side: if($horizontal == "left", "right", "left");

    border-#{$vertical}-width: $width;
    border-#{$h-side}-width: $width;
  }

  &::after {
    $v-side: if($vertical == "top", "bottom", "top");

    border-#{$v-side}-width: $width;
    border-#{$horizontal}-width: $width;
  }

  &:hover {
    color: black;

    &::before,
    &::after {
      border-color: $hover;
      transition: border-color 0s, width $duration, height $duration;
      width: 100%;
      height: 100%;
    }

    &::before {
      transition-delay: 0s, 0s, $duration;
    }

    &::after {
      transition-delay: 0s, $duration, 0s;
    }
  }
}

.draw-border {
  @include btn-border-drawing(#a9a6ff, #8884ff, 4px, bottom, right);
}
.input-field {
  position: relative;
}
.label-forms {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  color: #696969;
  transition: 0.2s all;
  cursor: text;
}
.input-forms {
  background: none;
  width: 100%;
  border: 0;
  outline: 0;
  padding: 0.25rem 0;
  border-bottom: 3px solid #696969;
  box-shadow: none;
  color: #111;
}
.input-forms:invalid {
  outline: 0;
}
.input-forms:focus,
.input-forms:valid {
  border-color: #8884ff;
}
.input-forms:focus ~ label,
.input-forms:valid ~ label {
  font-size: 14px;
  top: -24px;
  color: #8884ff;
}
