.navbar-opacity {
  background: none;
}
.navbar-hr {
  display: block;
  margin-top: -2px;
  border-style: solid;
  border-color: black;
  border-width: 2px;
}
.custom-navbar-mr {
  flex: 1;
  margin: auto !important;
  display: flex;
  justify-content: space-around;
}
.banner-back {
  z-index: 1;
  margin-bottom: -5em;
}
.banner-shadow {
  text-shadow: 1.75px 1.75px #5e5959;
  font-weight: bolder;
}
.dropdown:hover > .dropdown-menu {
  display: block;
}

.nav-text {
  letter-spacing: 2px;
  font-size: 1.75em;
}
/* Underline From Center */
.hvr-underline-from-center {
  display: inline-block;
  vertical-align: middle;
  -webkit-transform: translateZ(0);
  transform: translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  -moz-osx-font-smoothing: grayscale;
  position: relative;
  overflow: hidden;
}
.hvr-underline-from-center:before {
  content: "";
  position: absolute;
  z-index: -1;
  left: 50%;
  right: 50%;
  bottom: 0;
  background: black;
  height: 4px;
  -webkit-transition-property: left, right;
  transition-property: left, right;
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -webkit-transition-timing-function: ease-out;
  transition-timing-function: ease-out;
}
.hvr-underline-from-center:hover:before,
.hvr-underline-from-center:focus:before,
.hvr-underline-from-center:active:before {
  left: 0;
  right: 0;
}

.nav-hvr {
  position: relative;
  display: block;
  text-decoration: none;
  color: #262626;
  transition: 0.5s;
  z-index: 1;
  &:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-top: 2px solid #262626;
    border-bottom: 2px solid #262626;
    transform: scaleY(2);
    opacity: 0;
    transition: 0.3s;
  }
  &:after {
    content: "";
    position: absolute;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #262626;
    transform: scale(0);
    opacity: 0;
    transition: 0.3s;
    z-index: -1;
  }
  &:hover {
    color: white;
    text-decoration: none;
    &:before {
      transform: scaleY(1);
      opacity: 1;
    }
    &:after {
      transform: scaleY(1);
      opacity: 1;
    }
  }
}
